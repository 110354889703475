/**
 * Maskinia - Remove call for tierprices
 */

import {ReactElement} from 'Type/Common.type';
import {
    ProductPriceComponent as SourceProductPriceComponent
} from 'SourceComponent/ProductPrice/ProductPrice.component';

import './ProductPrice.style';
import {DisplayProductPricesInCatalog} from "SourceComponent/ProductPrice/ProductPrice.config";
import {PriceSchema} from "Component/ProductPrice/ProductPrice.type";
import {FormattedMoney} from "Util/Product/Product.type";

/**
 * Product price
 * @class ProductPrice
 * @namespace Component/ProductPrice/Component
 */
export class ProductPriceComponent extends SourceProductPriceComponent {

    //Maskinia - always show priceschema.
    getCurrentPriceSchema(): Partial<PriceSchema> {
        const {
            variantsCount,
            price: {
                finalPrice: {
                    value: contentPrice = 0,
                } = {},
            } = {},
        } = this.props;

        //Maskinia hardcoded to true
        const isSchemaRequired = true;
        if (variantsCount > 1) {
            return isSchemaRequired ? {itemProp: 'lowPrice', content: contentPrice} : {};
        }

        return isSchemaRequired ? {itemProp: 'price', content: contentPrice} : {};
    }

    renderPrice(price: Partial<FormattedMoney>, label: string | ReactElement): ReactElement {
        const {
            discountPercentage,
        } = this.props;

        const {
            value: priceValue,
            valueFormatted: priceFormatted = 0,
        } = price;

        const {itemProp, content} = this.getCurrentPriceSchema();

        // Use <ins></ins> <del></del> to represent new price and the old (deleted) one
        const PriceSemanticElementName = discountPercentage > 0 ? 'ins' : 'span';

        if ((!priceValue && priceValue !== 0)) {
            return null;
        }
        if (window.actionName.type === 'PRODUCT') {
            //Maskinia - remove itemScope in span and add itemprop pricecurrency
            return (
                <PriceSemanticElementName block="ProductPrice" elem="Price">
                    {this.renderPriceBadge(label)}
                    <span
                        block="ProductPrice"
                        elem="PriceValue"
                    >
                        <meta itemProp={itemProp} content={String(content)}/>
                        {priceFormatted}
                </span>
                </PriceSemanticElementName>
            );
        }
        return (
            <PriceSemanticElementName block="ProductPrice" elem="Price">
                {this.renderPriceBadge(label)}
                <span
                    block="ProductPrice"
                    elem="PriceValue"
                >
                    <meta itemProp="priceCurrency" content="SEK"/>
                    <meta itemProp={itemProp} content={String(content)}/>

                    {priceFormatted}
                </span>
            </PriceSemanticElementName>
        );
    }

    //Switch order on price and old price.
    renderDefaultPrice(defaultLabel: string | null = null): ReactElement {
        const {
            price: {finalPrice = {}, finalPriceExclTax = {}} = {},
            label,
        } = this.props;

        return (
            <>
                {this.renderPriceWithOrWithoutTax(finalPrice, finalPriceExclTax, defaultLabel || label)}
                {this.renderOldPrice()}
                {this.renderSchema()}
            </>
        );
    }

    renderBundlePrice(): ReactElement {
        const {
            originalPrice: {
                minFinalPrice = {} as FormattedMoney,
                minFinalPrice: {value: minValue = 0} = {},
                maxFinalPrice = {} as FormattedMoney,
                maxFinalPrice: {value: maxValue = 0} = {},
                minFinalPriceExclTax = {} as FormattedMoney,
                maxFinalPriceExclTax = {} as FormattedMoney,
                minRegularPrice = {} as FormattedMoney,
                maxRegularPrice = {} as FormattedMoney,
                minRegularPrice: {value: minRegularValue = 0} = {},
                maxRegularPrice: {value: maxRegularValue = 0} = {},
            },
        } = this.props;

        if (minValue === maxValue) {
            const renderer = (minValue === 0)
                ? this.renderDefaultPrice()
                : this.renderPriceWithOrWithoutTax(minFinalPrice, minFinalPriceExclTax);

            return (
                <>
                    {minValue < minRegularValue && this.renderRegularPrice(minRegularPrice)}
                    {renderer}
                </>
            );
        }

        return (
            <>
                <meta itemProp="priceCurrency" content="SEK"/>
                <div block="ProductPrice" elem="BundleFrom" mods={{hasDiscount: minValue < minRegularValue}}>
                    {minValue > 0 && this.renderPriceBadge(__('from'))}
                    {minValue < minRegularValue && this.renderRegularPrice(minRegularPrice)}
                    {this.renderPriceWithOrWithoutTax(minFinalPrice, minFinalPriceExclTax)}
                </div>
                <div block="ProductPrice" elem="BundleTo" mods={{hasDiscount: maxValue < maxRegularValue}}>
                    {maxValue > 0 && this.renderPriceBadge(__('to'))}
                    {maxValue < maxRegularValue && this.renderRegularPrice(maxRegularPrice)}
                    {this.renderPriceWithOrWithoutTax(maxFinalPrice, maxFinalPriceExclTax)}
                </div>
            </>
        );
    }

    /**
     * Maskinia - Remove call for tierprices
     */
    render(): ReactElement {
        const {
            price: {
                finalPrice,
                originalPrice,
                finalPrice: {
                    value: finalPriceValue = 0,
                } = {},
            } = {},
            priceType,
            isPreview,
            discountPercentage,
            mix,
        } = this.props;

        const {displayTaxInPrice} = this.props;

        if (!finalPrice || !originalPrice) {
            return this.renderPlaceholder();
        }

        const {[priceType]: renderer} = this.pricePreviewRenderMap;

        return (
            <div
                block="ProductPrice"
                mods={{hasDiscount: discountPercentage !== 0, isPreview}}
                mix={mix}
                aria-label={`Product price: ${finalPriceValue}`}
            >
                {isPreview && renderer && renderer()}
                {(!isPreview || !renderer) && this.renderDefaultPrice()}
                {(displayTaxInPrice === DisplayProductPricesInCatalog.EXCL_TAX) &&
                    <span
                        aria-label={__('Product price excl. tax')}
                        block="ProductPrice"
                        elem="SubPrice"
                    >
                {__('Excl. tax')}
            </span>
                }
            </div>
        );
    }
}

export default ProductPriceComponent;
