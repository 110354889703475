/**
 * Changes for backorder visible on product listing.
 */


import {
    ProductCardComponent as SourceProductCardComponent
} from 'SourceComponent/ProductCard/ProductCard.component';
import {ProductType} from 'SourceComponent/Product/Product.config';
import {ReactElement} from 'Type/Common.type';
import {ProductComponentProps} from 'SourceComponent/Product/Product.type';
import './ProductCard.style';
import TextPlaceholder from "Component/TextPlaceholder";
import {TextPlaceHolderLength} from "Component/TextPlaceholder/TextPlaceholder.config";
import Html from '@scandipwa/scandipwa/src/component/Html';
import fynd from 'Style/icons/fynd.svg';
import approved from 'Style/icons/approved.svg';
import campaign from 'Style/icons/kampanj.svg';
import blackweekend from 'Style/icons/blackweekend.svg';
import begagnat from 'Style/icons/begagnat.svg';
import Image from "Component/Image";
import {ImageRatio} from "Component/Image/Image.type";

/** @namespace Component/ProductCard/Component */
export class ProductCardComponent extends SourceProductCardComponent {

    renderBrand(withMeta = false): ReactElement {
        const {
            product: {
                attributes: {brand} = {},
            },
        } = this.props;

        if (!brand) {
            return null;
        }

        const brandLabel = brand.attribute_options[brand.attribute_value].label;

        return (
            <>
                {withMeta && <meta itemProp="brand" content={brandLabel}/>}
                <h4 block={this.className} elem="Brand" itemProp="brand">
                    <TextPlaceholder content={brandLabel}/>
                </h4>
            </>
        );
    }

    renderOrfalaNr(): ReactElement {
        const {product: {sku, name}} = this.props;
        if (!sku) {
            return null;
        }
        let content;
        //Only do this when sku have a value.
        if (sku?.charAt(4) == '-') {
            //Standard sku will have - on position 4.
            content = sku?.substring(5);
        } else {
            //Not standard. Find first - and remove it.
            content = sku?.substring(sku?.indexOf('-') + 1);
        }

        return (
            <>
                <h4 block={this.className} elem="orfalanr" itemProp="article number">
                    <TextPlaceholder content={content}/>
                </h4>
            </>
        );
    }

    renderShortDescriptionContent(): ReactElement {
        const {product: {short_description}} = this.props;
        const {html} = short_description || {};

        if (!html) {
            return null;
        }

        //Check if table exist, then remove it.
        let clearedHtml = html || "";
        if (clearedHtml.indexOf("<br /> <br />") > 0) {
            clearedHtml = clearedHtml.substring(0, clearedHtml.indexOf("<br /> <br />"));
        }
        //Take the first sentence.
        if (clearedHtml.indexOf(".")) {
            clearedHtml = clearedHtml.substring(0, clearedHtml.indexOf(".") + 1);
        }

        const htmlWithItemProp = `<div itemProp="description">${clearedHtml}</div>`;
        return (
            <h4 block="ProductActions" elem="ShortDescription">
                {html
                    ? <Html content={htmlWithItemProp}/>
                    : <p><TextPlaceholder length={TextPlaceHolderLength.LONG}/></p>}
            </h4>
        );
    }


    renderCardContent(): ReactElement {
        const {renderContent, product: {name}} = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            //Removed {this.renderBrand()}
            //remove {this.renderReviews()}
            this.renderCardLinkWrapper((
                <div block="ProductCard" elem="LinkInnerWrapper" mods={{loaded: !!name}}>
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            {this.renderApprovedIcon()}
                            {this.renderPicture()}
                        </figure>
                    </div>
                    <div block="ProductCard" elem="Content">
                        {this.renderBrand()}
                        {this.renderName(false)}
                        {this.renderOrfalaNr()}
                        {this.renderStock()}
                        {this.renderPrice()}
                    </div>
                    <div block="ProductCard" elem="VisibleOnHover">
                        {this.renderVisibleOnHover()}
                    </div>
                </div>
            ))
        );
    }

    //Maskinia - add logic for adding Maskinia Approved icon.
    renderApprovedIcon(): ReactElement {
        if (!this.props.product.id) {
            return null;
        }
        //return null
        ///@ts-ignore
        let maskiniaApproved = '';
        const {product: {attributes}} = this.props;
        //@ts-ignore
        if (attributes.highlight) {
            //@ts-ignore
            maskiniaApproved = attributes.highlight.attribute_options[attributes.highlight.attribute_value].label;
        }

        if (maskiniaApproved == '') {
            return null;
        }

        switch (maskiniaApproved.toLowerCase()) {
            case 'fynd':
                return (<div className="MaskiniaApprovedIcon">
                    <img src={fynd}/>
                </div>);
            case 'approved':
                return (<div className="MaskiniaApprovedIcon">
                    <img src={approved}/>
                </div>);
            case 'kampanj':
                return (<div className="MaskiniaApprovedIcon">
                    <img src={campaign}/>
                </div>);
            case 'blackweekend':
                return (<div className="MaskiniaApprovedIcon">
                    <img src={blackweekend}/>
                </div>);
            case 'beg':
                return (<div className="MaskiniaApprovedIcon">
                    <img src={begagnat}/>
                </div>);
            default:
                return (
                    <div className="MaskiniaApproved">
                        <h2>{maskiniaApproved}</h2>
                    </div>
                );
        }
    }

    renderPicture(mix = {}): ReactElement {
        const {product: {id, name}, thumbnail, onLoad} = this.props;

        //Maskinia - remove alt image.
        return (
            <>
                <Image
                    imageRef={this.imageRef}
                    src={thumbnail}
                    alt={name}
                    ratio={ImageRatio.IMG_CUSTOM}
                    mix={{block: 'ProductCard', elem: 'Picture', mix}}
                    isPlaceholder={!id}
                    onImageLoad={onLoad}
                />
            </>
        );
    }

    renderCardListContent(): ReactElement {
        const {
            children, layout, renderContent, product: {name},
        } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }


        return this.renderCardLinkWrapper((
            <div block="ProductCard" elem="Link">
                <div block="ProductCard" elem="FigureReview">
                    <figure block="ProductCard" elem="Figure">
                        {this.renderApprovedIcon()}
                        {this.renderPicture()}
                    </figure>
                </div>
                <div block="ProductCard" elem="Content ContentNo2" mods={{layout}}>
                    <div block="ProductCard" elem="MainInfo">
                        {this.renderBrand()}
                        {this.renderMainDetails()}
                        {this.renderShortDescriptionContent()}
                        {this.renderOrfalaNr()}
                    </div>
                </div>
                <div block="ProductCard" elem="Content ContentNo3" mods={{layout}}>
                    {this.renderStock()}
                    {this.renderConfigurableOptions()}
                    {this.renderProductActions()}
                </div>
                <div block="ProductCard" elem="Content ContentNo4" mods={{layout}}>
                    {this.renderPrice()}
                    <div block="ProductCard-CTA-wrapper">
                        <div className="ProductCard-ProductActions">
                            {this.renderAddToCart()}
                        </div>
                    </div>
                </div>
                <div block="ProductCard" elem="AdditionalContent">
                    {children}
                </div>

            </div>
        ));
    }

}

export default ProductCardComponent;
