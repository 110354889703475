/**
 * Maskinia - change text on no product found page.
 */


import {ReactElement} from 'SourceType/Common.type';
import './ProductList.style';
import {
    ProductListComponent as SourceProductListComponent
} from "SourceComponent/ProductList/ProductList.component";

/**
 * List of category products
 * @class ProductList
 * @namespace Component/ProductList/Component
 */
export class ProductListComponent extends SourceProductListComponent {


    getMailHtml(): ReactElement {
        const {title} = this.props;

        const subject = "Jag behöver följande artikel till min maskin";
        const mailto = "mailto:reservdelar@maskinia.se?subject=" + subject;
        return (<a href={mailto}>Skicka artikelfråga till Maskinia reservdelar.</a>);
    }

    renderNoProducts(): ReactElement {
        return (
            <div block="ProductList">
                <div
                    block="ProductList"
                    elem="ProductsMissing"
                >
                    <h2>{__('We are sorry!')}</h2>
                    <h3>{__('There were no products found matching your request.')}</h3>
                    <p>{__('All products is not enabled in the shop. If there is some product missing, send us a mail and we will see if we can add it.')}</p>
                    {this.getMailHtml()}
                    <br/>
                    <p></p>
                    <p>{__('Maskinia has the entire range from CNH, Develon, Bergmann, Engcon, but not everything is activated in the e-commerce. But make a request and we can activate the product.')}</p>
                </div>
            </div>
        );
    }
}

export default ProductListComponent;
