/**
 * Changes for backorder visible on product.
 */


import {
    ProductComponent as SourceProductComponent
} from 'SourceComponent/Product/Product.component';
import {ProductType} from 'SourceComponent/Product/Product.config';
import {ReactElement} from 'Type/Common.type';

import {ProductComponentProps} from 'SourceComponent/Product/Product.type';
import {lowPriorityLazy} from "SourceUtil/Request/LowPriorityRender";
import {IndexedCartItem} from "Store/Cart/Cart.type";


export const ProductConfigurableAttributes = lowPriorityLazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "product-misc" */ 'SourceComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.container'),
);
export const AddToCart = lowPriorityLazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "product-misc" */ 'SourceComponent/AddToCart'),
);
export const FieldContainer = lowPriorityLazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "product-misc" */ 'SourceComponent/Field'),
);
export const ProductCustomizableOptions = lowPriorityLazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "product-misc" */ 'SourceComponent/ProductCustomizableOptions'),
);
export const ProductBundleOptions = lowPriorityLazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "product-misc" */ 'SourceComponent/ProductBundleOptions'),
);
export const GroupedProductList = lowPriorityLazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "product-misc" */ 'SourceComponent/GroupedProductList'),
);
export const ProductCompareButton = lowPriorityLazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "product-misc" */ 'SourceComponent/ProductCompareButton'),
);
export const ProductDownloadableLinks = lowPriorityLazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "product-misc" */ 'SourceComponent/ProductDownloadableLinks'),
);
export const ProductDownloadableSamples = lowPriorityLazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "product-misc" */ 'SourceComponent/ProductDownloadableSamples'),
);
export const ProductWishlistButton = lowPriorityLazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "product-misc" */ 'SourceComponent/ProductWishlistButton'),
);

/**
 * Product
 * @class Product
 * @namespace Component/Product/Component
 */


export class ProductComponent extends SourceProductComponent {
    renderStock(): ReactElement {
        // @ts-ignore
        const {
            inStock,
            //Maskinia, added for backorder
            product: {
                // @ts-ignore
                stock_item: {qty} = {},
                type_id
            },
            //Maskinia, added for backorder
        } = this.props;


        //Maskinia, added for backorder
        let backorder = qty ?? 0;

        //Maskinia - CMS product could be in stock if its a simple product. Other is Order item.
        if (this.isCrmProducts() && backorder < 1 && inStock) {
            const backorderLabel = __('Order item')
            return <span block={this.className} elem="Stock">{backorderLabel}</span>;
        }

        if (type_id === ProductType.SIMPLE && backorder < 1 && inStock) {
            const backorderLabel = __('Backorder Item')
            return <span block={this.className} elem="Stock">{backorderLabel}</span>;
        }

        let selected = this.props.getActiveProduct();
        if (selected.stock_status != undefined) {
            const selectedInStock = selected.stock_status;
            // @ts-ignore
            backorder = selected.stock_item.qty ?? 0;
            if (selected.type_id === ProductType.SIMPLE && backorder < 1 && inStock) {
                const backorderLabel = __('Backorder Item')
                return <span block={this.className} elem="Stock">{backorderLabel}</span>;
            } else {
                if (selected.type_id === ProductType.CONFIGURABLE) {
                    const backorderLabel = __('Select variant');
                    return <span block={this.className} elem="Stock">{backorderLabel}</span>;
                }
            }
        }
        //Maskinia, added for backorder


        const stockStatusLabel = inStock ? __('In stock') : __('Out of stock');

        return <span block={this.className} elem="Stock">{stockStatusLabel}</span>;
    }

    isCrmProducts(): boolean {
        const {
            //Maskinia, added for backorder
            product,
            //Maskinia, added for backorder
        } = this.props;
        // @ts-ignore
        const {attributes: {source_system} = {}} = product;
        if (!source_system) {
            return false;
        }
        //Return true if label = CRM system
        return source_system.attribute_options[source_system.attribute_value].label == 'Cirrus CMS';
    }
}

export default ProductComponent;
