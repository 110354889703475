/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import {Location} from 'history';
import {PureComponent} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {Dispatch} from 'redux';

import {Page} from 'SourceComponent/Header/Header.config';
import {FilterPriceRange} from 'SourceQuery/ProductList.type';
import ProductListInfoDispatcher from 'SourceStore/ProductListInfo/ProductListInfo.dispatcher';
import {ReactElement} from 'SourceType/Common.type';
import {scrollToTop} from 'SourceUtil/Browser';
import {HistoryState} from 'SourceUtil/History/History.type';
import {waitForPriorityLoad} from 'SourceUtil/Request/LowPriorityLoad';
import {RootState} from 'SourceUtil/Store/Store.type';
import {getQueryParam, setQueryParams} from 'SourceUtil/Url';

import ProductList from 'SourceComponent/ProductList/ProductList.component';
import {
    PageBounds,
    ProductListComponentContainerPropKeys,
    ProductListComponentProps,
    ProductListContainerFunctions,
    ProductListContainerMapDispatchProps,
    ProductListContainerMapStateProps,
    ProductListContainerProps,
    ProductListContainerState,
} from 'SourceComponent/ProductList/ProductList.type';

import {ProductListContainer as SourceProductListContainer} from 'SourceComponent/ProductList/ProductList.container';

/** @namespace Component/ProductList/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): ProductListContainerMapStateProps => ({
    device: state.ConfigReducer.device,
});

/** @namespace Component/ProductList/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch): ProductListContainerMapDispatchProps => ({
    requestProductListInfo: (options) => ProductListInfoDispatcher.handleData(dispatch, options),
});

/** @namespace Component/ProductList/Container */
export class ProductListContainer extends SourceProductListContainer {
   

    componentDidUpdate(prevProps: ProductListContainerProps): void {
        const {
            sort,
            search,
            filter,
            pages,
            device,
            isPlp,
        } = this.props;

        const {
            sort: prevSort,
            search: prevSearch,
            filter: prevFilter,
            location: prevLocation,
        } = prevProps;

        const {pagesCount} = this.state;
        const pagesLength = Object.keys(pages).length;

        if (pagesCount !== pagesLength) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({pagesCount: pagesLength});
        }

        const prevPage = this._getPageFromUrl(prevLocation);
        const currentPage = this._getPageFromUrl();

        if (
            JSON.stringify(filter) !== JSON.stringify(prevFilter)
            || JSON.stringify(sort) !== JSON.stringify(prevSort)
            || currentPage !== prevPage
        ) {
            window.isPrefetchValueUsed = false;
        }

        // prevents requestPage() fired twice on Mobile PLP with enabled infinite scroll
        //Maskinia - Cause filter to not work.
        /*if (device.isMobile && this._getIsInfiniteLoaderEnabled() && isPlp) {
            return;
        }*/

        if (search !== prevSearch
            || currentPage !== prevPage
            || JSON.stringify(sort) !== JSON.stringify(prevSort)
            || JSON.stringify(filter) !== JSON.stringify(prevFilter)
        ) {
            this.requestPage(this._getPageFromUrl());
        }
    }

}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(
        ProductListContainer as unknown as React.ComponentType<
            RouteComponentProps & ProductListContainerProps
        >,
    ),
);
